'use client';

import { PAGE_TYPES, RETAILER_IDS } from '@/app/constants';
import { useSelector } from 'react-redux';
import { appSettingsState, setPNcDisclaimer, setPromotionHeadline, useDispatch } from '@/app/lib/redux';
import { useEffect, useState } from 'react';
import { mapProductsToEcommerceItems, trackSSTEvent } from '@/app/utils/tracking';

import { v4 } from 'uuid';
import { usePathname } from 'next/navigation';
import { IProduct } from '@mybestbrands/types';
import useCmp from '@/app/ui/hooks/useCmp';

type PageViewEventsProps = {
  pageType: PAGE_TYPES;
  pageContent: string;
  promotionHeadline?: string;
  firstListingProductIds?: string[];
  products?: IProduct[];
};

type ViewContentProps = {
  viewContent: {
    content_ids: string[];
    content_type: string;
    content_name: string;
    content_category: string;
    currency?: string;
    value?: number;
  };
};

type PurchaseProps = {
  product: IProduct;
  transactionId: string;
};

const getContentGroup = (pathname: string, pageType: PAGE_TYPES): string => {
  let contentGroup = 'category_plp';

  if (pathname.match('marken')) contentGroup = 'brand_plp';
  if (pathname.match('online-shops')) contentGroup = 'retailer_plp';
  if (pathname.match('inspiration|styles')) contentGroup = 'editorial';
  if (pageType === PAGE_TYPES.HOME) contentGroup = 'home';
  if (pageType === PAGE_TYPES.DETAILS) contentGroup = 'pdps';

  return contentGroup;
};

export const ProductDetailEvents = ({ product }: { product: IProduct }) => {
  const { epc } = product;
  const consents = useCmp('');
  const haveConsents = Object.keys(consents).length > 0;

  useEffect(() => {
    if (!haveConsents) return;
    trackSSTEvent('view_item', {
      currency: 'EUR',
      value: epc / 100,
      items: mapProductsToEcommerceItems([product]),
    });
  }, [haveConsents]);

  return null;
};

export const PageViewEvents = ({
  pageType,
  pageContent,
  firstListingProductIds,
  products,
  promotionHeadline,
}: PageViewEventsProps) => {
  const { detailModalVisible } = useSelector(appSettingsState);
  const pathname = usePathname();
  const [eventId, setLocalEventId] = useState<string>('');
  const consents = useCmp('');
  const haveConsents = Object.keys(consents).length > 0;
  const dispatch = useDispatch();

  useEffect(() => {
    if (pageType === PAGE_TYPES.LIST) {
      dispatch(setPromotionHeadline(promotionHeadline || ''));
    }
  }, [pageType, promotionHeadline]);

  useEffect(() => {
    const isAnyProductFromPnC = (products || []).some(
      (product) => product.retailerId === RETAILER_IDS.PEEK_CLOPPENBURG
    );
    dispatch(setPNcDisclaimer(isAnyProductFromPnC));
    setTimeout(() => {
      setLocalEventId(v4());
    }, 500);
  }, [pathname]);

  useEffect(() => {
    if (!eventId || !haveConsents) return;

    if (typeof window.gtag === 'function') {
      window.firstListingProductIDs = firstListingProductIds;
      const xPageType = detailModalVisible ? 'details' : pageType;
      trackSSTEvent('page_view', {
        'x-fb-event-id': eventId,
        'x-page-type': xPageType,
        'x-page-content': pageContent,
        'x-first-listing-product-ids': (firstListingProductIds || []).join(),
      });

      trackSSTEvent('view_' + getContentGroup(window.location.pathname, pageType), null);

      if (products) {
        trackSSTEvent('view_item_list', {
          item_list_id: pageContent
            .split(' ')
            .map((e) => e.toLowerCase())
            .join('_'),
          item_list_name: pageContent,
          items: mapProductsToEcommerceItems(products),
        });
      }
    }

    if (typeof window.fbq === 'function') {
      window.fbq('track', 'PageView', null, { eventID: eventId });
    }
  }, [eventId, haveConsents]);
  return null;
};

export const FbViewContentEvent = ({ viewContent }: ViewContentProps) => {
  const pathname = usePathname();
  const [eventId, setLocalEventId] = useState<string>('');
  const consents = useCmp('');
  const haveConsents = Object.keys(consents).length > 0;

  useEffect(() => {
    setTimeout(() => {
      setLocalEventId(v4());
    }, 500);
  }, [pathname]);

  useEffect(() => {
    if (!eventId || !viewContent || !haveConsents) return;
    // track Pixel if fbq is available (have consents)
    if (typeof window.fbq === 'function') window.fbq('track', 'ViewContent', viewContent, { eventID: eventId });

    // SST events consent will be handled in GTM
    trackSSTEvent('x_fb_view_content', {
      'x-fb-custom-data': JSON.stringify(viewContent),
      'x-fb-event-id': eventId,
    });
  }, [eventId, haveConsents]);
  return null;
};

export const FbPurchaseEvent = ({ product, transactionId }: PurchaseProps) => {
  const { epc, productId, name, categoryTagNames } = product;

  useEffect(() => {
    setTimeout(() => {
      if (typeof window.fbq === 'function') {
        window.fbq(
          'track',
          'Purchase',
          {
            value: epc / 100,
            currency: 'EUR',
            content_ids: [productId],
            content_type: 'product',
            content_name: name,
            content_category: categoryTagNames.join('/'),
          },
          { eventID: transactionId }
        );
      }
    }, 500);
  }, []);

  return null;
};
