'use client';
import { ESInspirationDetailsData, InspirationType } from '@mybestbrands/types';
import styles from './styles.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import React, { useState } from 'react';
import HorizontalLine from '@/app/ui/components/horizontalline';
import Editor from '@/app/ui/components/inspiration/editor';
import { useTranslation } from 'next-i18next';
import VisibilityTracker from '@/app/ui/components/tracking/visibilityTracker';
import { trackSSTEvent } from '@/app/utils/tracking';

type InspirationCardsProps = {
  title?: string;
  cards?: ESInspirationDetailsData[] | InspirationType[] | null;
};

const inspirationPerView = 12;

const InspirationCards = ({ title, cards }: InspirationCardsProps) => {
  const [items, setItems] = useState(inspirationPerView);
  const { t } = useTranslation(['common']);

  if (!cards) return null;

  const handleLoadMore = () => {
    setItems((prevCards) => prevCards + inspirationPerView);
  };

  const cardsElements = cards.slice(0, items).map((c, idx) => {
    const finalTitle = c.headline || title || '';

    const trackingData = {
      creative_name: 'Inspiration',
      creative_slot: 'inspiration_' + idx,
      promotion_id: finalTitle.toLowerCase().split(' ').join('_'),
      promotion_name: finalTitle,
    };

    return (
      <Link
        href={c.uri}
        key={finalTitle}
        className={'mb-3'}
        onClick={() => trackSSTEvent('select_promotion', trackingData)}>
        <Image
          src={c.image.mediaItemUrl}
          alt={c.image.altText}
          width={307}
          height={213}
          sizes="100vw"
          style={{ width: '100%', height: 'auto' }}
          className={'mb-3'}
        />
        {finalTitle && <div className={styles.headline}>{finalTitle}</div>}
        <HorizontalLine className={'my-2'} />
        <Editor editor={c.editor} isCard={true} />
        <VisibilityTracker eventName={'view_promotion'} data={trackingData} />
      </Link>
    );
  });

  return (
    <>
      {title && <div className={styles.title}>{title}</div>}
      <div data-testid="inspiration-cards" className={styles.listing}>
        {cardsElements}
      </div>
      {cards.length > items && (
        <div className="row justify-content-center my-5 mx-auto">
          <button className={`btn btn-white-hover-black ${styles.width}`} onClick={handleLoadMore}>
            {t('common:MORE_INSPIRATIONS')}
          </button>
        </div>
      )}
    </>
  );
};
export default InspirationCards;
